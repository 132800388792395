import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import CloudCommands from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/cloud_commands_accordion';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud Alexa Support",
  "path": "/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/",
  "dateChanged": "2020-11-06",
  "author": "Mike Polinowski",
  "excerpt": "How to Use INSTAR Cloud Alexa Skill",
  "image": "./MD_SearchThumb_Cloud_Alexa.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Alexa.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR Cloud Alexa Support' dateChanged='2020-11-06' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to Use INSTAR Cloud Alexa Skill.' image='/images/Search/MD_SearchThumb_Cloud_Alexa.png' twitter='/images/Search/MD_SearchThumb_Cloud_Alexa.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/' crumbLabel="Alexa" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "instar-triggers--actions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-triggers--actions",
        "aria-label": "instar triggers  actions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Triggers & Actions`}</h2>
    <h3 {...{
      "id": "phrases-the-instar-cloud-skill-supports",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#phrases-the-instar-cloud-skill-supports",
        "aria-label": "phrases the instar cloud skill supports permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Phrases the INSTAR Cloud Skill supports`}</h3>
    <CloudCommands mdxType="CloudCommands" />
    <p>{`If you have any questions about our INSTAR Cloud Skill or if you have any suggestions, please contact us. Note: This skill requires account linking. If you have multiple accounts in the INSTAR Cloud, Alexa will only enable and link this skill to the account that is currently logged in / linked.`}</p>
    <p><strong parentName="p">{`*`}{` Status:`}</strong></p>
    <ul>
      <li parentName="ul">{`"unseen": `}<em parentName="li">{`unseen, not seen, not yet seen, new, fresh, untouched, newly, added, unviewed`}</em></li>
      <li parentName="ul">{`"seen": `}<em parentName="li">{`seen, watched, looked at, already seen, old, viewed`}</em></li>
      <li parentName="ul">{`"starred": `}<em parentName="li">{`marked, noted, checked, starred`}</em></li>
      <li parentName="ul">{`"locked": `}<em parentName="li">{`locked, protected, blocked`}</em></li>
    </ul>
    <p><strong parentName="p">{`*`}{` Objects:`}</strong></p>
    <ul>
      <li parentName="ul">{`"Human": `}<em parentName="li">{`human, person, people, peoples, persons, humans`}</em></li>
      <li parentName="ul">{`"Vehicle": `}<em parentName="li">{`car, cars, truck, trucks, vehicle, vehicles`}</em></li>
      <li parentName="ul">{`"Animal": `}<em parentName="li">{`animal, animals, pets, pet`}</em></li>
    </ul>
    <p><strong parentName="p">{`*`}{` Switch:`}</strong></p>
    <ul>
      <li parentName="ul">{`"activate": `}<em parentName="li">{`activate, enable, active, on, turn on`}</em></li>
      <li parentName="ul">{`"deactivate": `}<em parentName="li">{`deactivate, disable, off, turn off`}</em></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      